<template>
  <div ref="game" class="game" :class="dynamicClasses">
    <div class="game-desktop">
      <!--   header   -->
      <div
        class="game-desktop__header"
        :class="{ 'header__date-time': totalGames < 3, 'is-finished': game.is_live || game.game_status !== 4 }"
      >
        <div class="game-desktop__header__status">
          <div v-if="game.is_live" class="game-desktop__header__status--live">
            <div class="game-desktop__header__status--live__text">En vivo</div>
            <div class="game-desktop__header__status--live__circle" />
          </div>
          <div v-else-if="game.game_status === 4" class="game-desktop__header__status--pre">
            <div v-if="!areGamesToday && totalGames < 3">
              <div>
                <span style="margin-right: 4px;" class="is-uppercase">{{
                  game.dateObject | toFormattedDate('iii')
                }}</span>
                <span class="is-capitalized">{{ game.dateObject | toFormattedDate('d-LLL-yyyy') }}</span>
              </div>
              <div style="margin: 0 4px">|</div>
              <div class="status--pre__hour">{{ game.dateObject | toFormattedDate('H:mm') }} HRS</div>
            </div>
            <div v-else>{{ game.dateObject | toFormattedDate('H:mm') }} HRS</div>
          </div>
          <div v-else class="game-desktop__header__status--post">Finalizado</div>
        </div>
        <div class="game-desktop__header__see" @click="$emit('click', game.game_id)">Ver</div>
      </div>
      <div class="game-desktop__body">
        <div v-if="game.homeTeam" class="game-desktop__body__team">
          <div class="logo">
            <img :src="game.homeTeam.logo" alt="home team" />
          </div>
          <div class="name">{{ game.homeTeam.acronym }}</div>
        </div>
        <div class="game-desktop__body__score">
          <template v-if="(game.game_status !== 4 || game.is_live) && game.score">
            <span v-if="hasPenalties" class="penalties home">({{ game.penalties_score_home_team }})</span>
            <span class="score">{{ game.score[0] }}</span>
          </template>
          <span class="minus">{{ game.game_status === 4 && !game.is_live ? 'VS' : '-' }}</span>
          <template v-if="(game.game_status !== 4 || game.is_live) && game.score">
            <span class="score">{{ game.score[1] }}</span>
            <span v-if="hasPenalties" class="penalties away">({{ game.penalties_score_visiting_team }})</span>
          </template>
        </div>
        <div v-if="game.awayTeam" class="game-desktop__body__team">
          <div class="name">{{ game.awayTeam.acronym }}</div>
          <div class="logo">
            <img :src="game.awayTeam.logo" alt="away team" />
          </div>
        </div>
      </div>
    </div>
    <div class="game-mobile">
      <div class="game-mobile__container">
        <div class="game-mobile__header">
          <div class="game-mobile__header__status">
            <div v-if="game.is_live" class="game-mobile__header__status--live">
              <div class="game-mobile__header__status--live__text">En vivo</div>
              <div class="game-mobile__header__status--live__circle" />
            </div>
            <div v-else-if="game.game_status === 1">Finalizado</div>
            <div v-else-if="game.dateObject">{{ game.dateObject | toFormattedDate('H:mm') }} HRS</div>
          </div>
        </div>
        <div v-if="game.score" class="game-mobile__team">
          <div class="game-mobile__team__info">
            <div class="logo">
              <img :src="game.homeTeam.logo" alt="home team" />
            </div>
            <div class="name">{{ game.homeTeam.acronym }}</div>
          </div>
          <div class="game-mobile__team__score home">
            {{ game.game_status !== 4 || game.is_live ? game.score[0] : '-' }}
          </div>
        </div>
        <div v-if="game.score" class="game-mobile__team">
          <div class="game-mobile__team__info">
            <div class="logo">
              <img :src="game.awayTeam.logo" alt="away team" />
            </div>
            <div class="name">{{ game.awayTeam.acronym }}</div>
          </div>
          <div class="game-mobile__team__score away">
            {{ game.game_status !== 4 || game.is_live ? game.score[1] : '-' }}
          </div>
        </div>
        <div class="game-mobile__see" @click="$emit('click', game.game_id)">
          <div>Ver</div>
        </div>
      </div>
    </div>
    <!--  desktop single game  -->
    <div class="game-desktop-single-game">
      <div class="status">
        <div v-if="game.is_live" class="status--live">
          <div class="status--live__text">En vivo</div>
          <div class="status--live__circle" />
        </div>
        <div v-else-if="game.game_status === 4" class="status--pre">
          <div v-if="!areGamesToday" class="status--pre__day">
            <span style="margin-right: 4px;" class="is-uppercase">{{ game.dateObject | toFormattedDate('iii') }}</span>
            <span class="is-capitalized">{{ game.dateObject | toFormattedDate('d-LLL-yyyy') }}</span>
          </div>
          <div class="status--pre__hour">{{ game.dateObject | toFormattedDate('H:mm') }} HRS</div>
        </div>
        <div v-else class="status--post">Finalizado</div>
      </div>
      <div v-if="game.homeTeam" class="team">
        <div class="logo">
          <img :src="game.homeTeam.logo" alt="Home Team" />
        </div>
        <div class="name">
          {{ game.homeTeam.acronym }}
        </div>
      </div>
      <div class="score">
        <template v-if="(game.game_status !== 4 || game.is_live) && game.score">
          <span v-if="hasPenalties" class="penalties home">({{ game.penalties_score_home_team }})</span>
          <span class="score-number">{{ game.score[0] }}</span>
        </template>
        <span class="minus" :style="{ 'padding-bottom': game.game_status === 4 && !game.is_live ? '0' : '4px' }">{{
          game.game_status === 4 && !game.is_live ? 'VS' : '-'
        }}</span>
        <template v-if="(game.game_status !== 4 || game.is_live) && game.score">
          <span class="score-number">{{ game.score[1] }}</span>
          <span v-if="hasPenalties" class="penalties away">({{ game.penalties_score_visiting_team }})</span>
        </template>
      </div>
      <div v-if="game.awayTeam" class="team">
        <div class="name">
          {{ game.awayTeam.acronym }}
        </div>
        <div class="logo">
          <img :src="game.awayTeam.logo" alt="Away Team" />
        </div>
      </div>
      <div class="see" @click="$emit('click', game.game_id)">Ver</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Game',
  props: {
    game: {
      type: Object,
      required: true,
    },
    // 3 is desktop, 2 is tablet and 1 is mobile
    layoutType: {
      type: Number,
      default: 3,
    },
    totalGames: {
      type: Number,
      default: 4,
    },
    areGamesToday: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      gameWidth: 0,
    };
  },
  computed: {
    hasPenalties() {
      return this.game.penalties_score_home_team || this.game.penalties_score_visiting_team;
    },
    gameScore() {
      return this.game.game_status !== 4 && this.game.score ? `${this.game.score[0]} - ${this.game.score[1]}` : '-';
    },
    dynamicClasses() {
      const dynamicClasses = [];
      if (this.layoutType === 1) {
        if (this.totalGames === 2) {
          dynamicClasses.push('tablet');
        } else if (this.totalGames > 2) {
          dynamicClasses.push('mobile');
        }
      } else if (this.layoutType === 2 && this.totalGames > 1) {
        dynamicClasses.push('tablet');
      } else if (this.totalGames === 1) {
        dynamicClasses.push('game-desktop-single-game');
      }
      return dynamicClasses;
    },
  },
};
</script>

<style scoped lang="scss">
.game {
  width: 100%;
  height: 74px;
  border-radius: 6px;
  border: solid 1px #d2d2d2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-mobile {
    display: none;
  }

  & .game-desktop-single-game {
    display: none;
  }

  &-desktop {
    width: inherit;
    height: 100%;
    padding: 6px 0 0;
    position: relative;

    &__header {
      width: inherit;
      display: grid;
      grid-template-columns: 55% 35%;
      grid-gap: 10px;

      &.header__date-time {
        grid-template-columns: 65% 25%;

        &.is-finished {
          grid-template-columns: 50% 50%;
        }
      }

      &__status {
        justify-self: flex-end;
        font-size: 11px;

        &--live {
          width: 64px;
          height: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          background-color: #3c3c3a;

          &__text {
            color: white;
            font-family: Avenir-Medium, sans-serif;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.8;
            margin-right: 4px;
            padding-top: 3px;
          }

          &__circle {
            height: 6px;
            width: 6px;
            border-radius: 50%;
            background-color: #aadd21;
          }
        }

        &--pre {
          & > div:first-child {
            display: flex;
            justify-content: center;
            justify-self: flex-end;
          }
        }
      }

      &__see {
        width: 45px;
        height: 15px;
        font-family: Avenir-Demi, sans-serif;
        padding: 4px 10px;
        border-radius: 4px;
        background-color: #cbee6e;
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.7;
        letter-spacing: -0.2px;
        text-align: center;
        color: #132839;
        cursor: pointer;
      }
    }

    &__body {
      width: inherit;
      height: 40px;
      display: grid;
      grid-template-columns: 1fr 16% 1fr;
      margin-top: 8px;
      color: #132839;

      &__score {
        white-space: nowrap;
        display: flex;
        justify-self: center;
        align-items: center;
      }

      & > div {
        align-self: center;
      }

      &__team {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & .name {
        font-family: Avenir-Demi, sans-serif;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.53;
        letter-spacing: -0.3px;
        text-align: center;
        color: #8c8c8c;
        padding-top: 4px;
        margin: 0 8px;
      }

      & .logo {
        height: 40px;
        width: 40px;

        & > img {
          height: 40px;
          width: 40px;
        }
      }
    }

    & .minus {
      font-family: Circular-Std-Medium, sans-serif;
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: -0.26px;
      text-align: center;
      color: #000;
      margin: 0 4px;
    }

    & .score {
      white-space: nowrap;
      font-family: Avenir-Pro-Bold, sans-serif;
      font-size: 22px;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.82;
      letter-spacing: -0.44px;
      text-align: left;
    }
  }

  &.tablet {
    .game-desktop {
      position: relative;
      padding: 2.5px 0 0;
      &__header {
        width: inherit;
        display: flex;
        justify-content: center;

        &__see {
          position: absolute;
          bottom: 4px;
          cursor: pointer;
        }
      }

      &__body {
        height: fit-content;
        margin-top: 4.5px;
        grid-template-columns: 1fr min-content 1fr;

        &__team {
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;

          &:last-child {
            flex-direction: column-reverse;
          }
        }

        & .name {
          font-size: 9px;
          margin: 6px 0 0;
        }

        & .logo {
          height: 30px;
          width: 30px;

          & > img {
            height: 30px;
            width: 30px;
          }
        }

        &__score {
          white-space: nowrap;
          align-self: flex-start;
          margin-top: 4px;
        }
      }
    }
  }

  &.mobile {
    .game-desktop {
      display: none;
    }

    .game-mobile {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1px 6px;

      &__container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        max-width: 64px;
      }

      &__header {
        font-family: Avenir-Demi, sans-serif;
        font-size: 8px;
        height: 12px;
        margin-bottom: 2px;

        &__status {
          &--live {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 12px;
            border-radius: 4px;
            background-color: #132839;

            &__text {
              color: white;
              margin-right: 4px;
            }

            &__circle {
              height: 5px;
              width: 5px;
              background-color: #aadd21;
            }
          }
        }
      }

      &__team {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 18px;

        &__info {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          & .logo {
            height: 18px;
            width: 18px;

            & > img {
              height: 18px;
              width: 18px;
            }
          }

          & .name {
            font-family: Avenir-Demi, sans-serif;
            font-size: 9px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.89;
            letter-spacing: -0.18px;
            text-align: center;
            color: #213646;
            margin-left: 4px;
          }
        }

        &__score {
          white-space: nowrap;
          height: 100%;
          background-color: #ecf2f6;
          min-width: 16px;

          &.home {
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
          }

          &.away {
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
          }
        }
      }

      &__see {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background-color: #cbee6e;
        height: 16px;
        margin-top: 2px;
        cursor: pointer;

        & > div {
          font-size: 9px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.89;
          letter-spacing: -0.18px;
          text-align: center;
          color: #132839;
        }
      }
    }
  }

  &.game-desktop-single-game {
    .game-desktop {
      display: none;
    }

    & .game-desktop-single-game {
      width: 100%;
      padding: 0 14px;
      display: grid;
      grid-template-columns: 1fr 1fr min-content 1fr 1fr;

      & > div {
        width: 100%;
        justify-self: center;
        align-self: center;
      }

      & .status {
        & > div {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
        }

        &--live {
          width: 100%;
          border-radius: 4px;
          background-color: #3c3c3a;

          &__text {
            font-family: Avenir-Medium, sans-serif;
            font-size: 16px;
            color: white;
          }

          &__circle {
            height: 12px;
            width: 12px;
            border-radius: 50%;
            background-color: #aadd21;
            margin-left: 14px;
          }
        }

        &--post {
          font-family: Avenir-Demi, sans-serif;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.53;
          letter-spacing: -0.3px;
          text-align: center;
          color: #132839;
        }

        &--pre {
          font-family: Avenir-Demi, sans-serif;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          color: #132839;
          padding-top: 4px;

          &__day {
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.53;
            letter-spacing: -0.3px;
            margin-bottom: 8px;
          }

          &__hour {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.67;
            letter-spacing: -0.24px;
            text-align: center;
          }
        }
      }

      & .team {
        display: flex;
        justify-content: center;
        align-items: center;

        & .logo {
          height: 40px;
          width: 40px;

          & > img {
            height: 40px;
            width: 40px;
          }
        }

        & .name {
          font-size: 15px;
          font-family: Avenir-Demi, sans-serif;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.53;
          letter-spacing: -0.3px;
          text-align: center;
          color: #8c8c8c;
          margin: 0 8px;
          padding-top: 4px;
        }
      }

      & .score {
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
        & .score-number {
          font-family: Avenir-Pro-Bold, sans-serif;
          font-size: 22px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.82;
          letter-spacing: -0.44px;
          text-align: left;
          color: #132839;
        }
        & .minus {
          font-family: Circular-Std-Medium, sans-serif;
          font-size: 13px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: -0.26px;
          text-align: center;
          color: #000;
          margin: 0 4px;
        }
      }

      & .see {
        width: 100%;
        border-radius: 4px;
        background-color: #cbee6e;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        font-family: Avenir-Demi, sans-serif;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.41;
        letter-spacing: -0.34px;
        text-align: center;
        color: #132839;
        cursor: pointer;
      }
    }
  }
}
.penalties {
  font-size: 15px;

  &.home {
    margin-right: 13px;
  }

  &.away {
    margin-left: 12px;
  }
}
</style>
